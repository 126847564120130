export const theme = {
  'dark-theme': {
    color: {
      background: '#252526',
      backgroundLight: '#333333',
      backgroundDark: '#1e1e1e',
      primary: '#6a9956',
      primaryLight: '#2ba549',
      primaryDark: '#207c37',
      text: '#ebebf0',
      textLight: '#f1f1f4',
      textDark: '#aaaaaa',
      textSmart: '#da8fff',
      textLink: '#70d7ff',
      textCredit: '#ffb340',
      success: '#248a3d',
      warning: '#ffb340',
      error: '#d70015',
      reliableHigh: '#b2efcb',
      reliableMedium: '#ffe59a',
      reliableLow: '#ffb2b2',
    },
    borderRadius: {
      small: '4px',
      medium: '8px',
      large: '20px',
    },
    spacing: {
      xxs: '3px',
      xs: '5px',
      sm: '10px',
      md: '15px',
      lg: '20px',
      xl: '25px',
      xxl: '30px',
    },
    navbar: {
      height: '56px',
    },
    font: {
      xxs: '0.6rem',
      xs: '0.8rem',
      sm: '0.95rem',
      md: '1.1rem',
      lg: '1.3rem',
      xl: '1.5rem',
      xxl: '2rem',
      heading: '3rem',
      banner: '4rem',
      normal: '400',
      medium: '500',
      bold: '700',
    },
    short: {
      animation: '0.5s',
    },
    normal: {
      animation: '1s',
    },
    long: {
      animation: '3s',
    },
  },
  'light-theme': {
    color: {
      background: '#e0e0e0',
      backgroundLight: '#eeeeee',
      backgroundDark: '#cacaca',
      primary: '#248a3d',
      primaryLight: '#2ba549',
      primaryDark: '#207c37',
      text: '#343434',
      textLight: '#484848',
      textDark: '#2f2f2f',
      textSmart: '#5e5ce6',
      textLink: '#0040dd',
      textCredit: '#5e5ce6',
      success: '#248a3d',
      warning: '#ffb340',
      error: '#d70015',
      reliableHigh: '#b2efcb',
      reliableMedium: '#ffe59a',
      reliableLow: '#ffb2b2',
    },
    borderRadius: {
      small: '4px',
      medium: '8px',
      large: '20px',
    },
    spacing: {
      xxs: '3px',
      xs: '5px',
      sm: '10px',
      md: '15px',
      lg: '20px',
      xl: '25px',
      xxl: '30px',
    },
    navbar: {
      height: '56px',
    },
    font: {
      xxs: '0.6rem',
      xs: '0.8rem',
      sm: '0.95rem',
      md: '1.1rem',
      lg: '1.3rem',
      xl: '1.5rem',
      xxl: '2rem',
      heading: '3rem',
      banner: '4rem',
      normal: '400',
      medium: '500',
      bold: '700',
    },
    short: {
      animation: '0.5s',
    },
    normal: {
      animation: '1s',
    },
    long: {
      animation: '3s',
    },
  },
};
