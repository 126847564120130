import './index.scss';

const Homepage = () => {
  // const navigate = useNavigate();
  // const toast = useToast();

  return <div className="homepage-screen-wrapper">Home</div>;
};

export default Homepage;
